@import 'src/shared/styles/_variables.scss';

.tableSectionContent {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
}

.wrapper {
  background-color: $white;
}

.footer {
  margin-top: 16px;
  margin-left: 32px;
}

.container {
  display: flex;
  position: relative;
  min-height: calc(100vh - 64px);

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.mapSection {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  top: 57px;
  min-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  overflow: hidden;
}

.mapSectionUnfold {
  width: 100% !important;
}

.collapseMapButton {
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 10;

  background-color: $neutral0;
}

.tableSection {
  width: 60%;
  min-width: 60%;
  padding-top: 10px;
  padding-left: 32px;
  margin-right: 32px;
  transition: width 100ms ease-in-out;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding-right: 24px;
  }
}

.tableSectionCollapsed {
  width: 0;
  min-width: 0;
  padding: 0;
  margin: 0;
}
