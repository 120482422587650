.dataTitle {
  text-transform: capitalize;
}

.orderType {
  svg {
    font-size: 18px;
    color: #007d9c;
    margin-right: 8px;
  }
}

.capitalizeText {
  text-transform: capitalize;
}

.tooltipEmail {
  > div > div:last-child > span {
    color: #fff;
  }
}

.tooltipBlock {
  display: flex;
  align-items: center;
  flex-direction: row;

  > button {
    margin: 0 5px;
  }

  > .email {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.radioGroup {
  display: flex;
  gap: 8px;
  label[class*="checked"]{
    span:last-of-type {
      color: white;
    }
  }
}