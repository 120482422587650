.homeItem {
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    span {
      color: #3f505a;
      font-size: 16px;
    }
  }
}