.form {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__item {
    margin-bottom: 0;

    > div > div:first-child { //div with label inside
      padding-bottom: 0;

      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        color: #50626C;
      }
    }
  }

  &__block {
    margin-top: 12px;
  }
}

.divider {
  margin: 12px 0;
}

.orderLineLabel {
  width: 100%;
  overflow: hidden;
  & > span:last-of-type {
    width: 100%;
    overflow: hidden;
  }
}

.orderLineWrapper {
  margin-bottom: 6px;
}

.orderLine {
  display: inline-flex;
  align-items: baseline;
  gap: 16px;
  width: 100%;
  &__code {
    font-family: 'Courier New', Courier, monospace !important;
    font-weight: 600;
  }
  &__total {
    font-weight: 600;
  }
  &__title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;font-style: italic;
  }
  &__icon {
    margin-left: auto;
  }
  span, p {
    text-align: start;
  }

  p {
    display: inline-block;
  }
}