.formItem {
  display: inline-block;
  margin: 0;

  >div>div>div {
    min-height: 21px !important;
  }
}

.rateBlock {
  white-space: nowrap;
}

.rate {
  display: inline;
  font-size: 21px;
  height: 21px;
  line-height: 21px !important;
  padding-inline: 5px;
  border: none !important;

  &::before {
    display: none !important;
  }

  &:first-of-type {
    padding-left: 0;
  }

  svg {
    font-size: 21px;
    fill: #B3BFC6;

    &:hover,
    &:focus,
    &:active {
      fill: #FFE600;
    }
  }
}

.rateNoSelect {
  display: inline;
  font-size: 21px;
  height: 21px;
  line-height: 21px !important;
  padding-inline: 5px;
  border: none !important;
  background-color: unset !important;
  cursor: default !important;
  user-select: none !important;


  &::before {
    display: none !important;
  }

  svg {
    font-size: 21px;
    fill: #B3BFC6;
  }
}

.selectedRate {
  svg {
    fill: #FFE600;
  }
}