.loader {
  width: 100%;
  height: calc(100vh - 307px);

  @media (min-width: 768px) and (max-width: 1369px) {
    height: calc(100vh - 233px);
  }

  @media (min-width: 1370px) {
    height: calc(100vh - 156px);
  }
}

.loader>.spin {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.loaderCompact {
  margin: 36px 0;
  text-align: center;
}

.spin>span>i,
.spin>div {
  color: #03a3bf;
}

.spin>div {
  font-size: 25px;
}