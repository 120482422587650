.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 20px 0;
  border-top: 1px solid #D5DBDF;
}

.message {
  font-size: 16px;
  color: #3f505a;
}

.lableSwitch {
  padding: 4px;
}