.breadcrumb {
  li {
    color: #70838e;
    font-size: 16px;

    // > span:last-child {
    //     color: #50626C;
    // }

    // &:last-child {
    //     color: #132B3A;
    // }

    span {
      text-transform: capitalize;
      color: #70838e;
      font-size: 16px;

      a:hover,
      a:active {
        background-color: transparent;
      }
    }

    &:last-child {
      span {
        display: inline-flex;
        align-items: center;
        color: #132b3a;
        &:first-child::after {
          content: attr(data-last-step);
          padding-left: 3px;
        }
      }
    } 
  }
}
