.closeIcon {
  display: none;
}

.commentsBlockWrapper {
  position: sticky;
  min-width: 430px;
  max-width: 430px;

  .commentsHoverElem {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -20px;
    top: 50%;
    width: 30px;
    height: 30px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 22px;
    border-radius: 40px;
    border-color: #E6F1F3;
    background-color: white;
    cursor: pointer;
    box-shadow: 0 2px 8px -2px rgba(0,0,0,0.05), 0 1px 4px -1px rgba(25,15,15,0.2), 0 0 1px 0 rgba(0,0,0,0.3);
    z-index: 101;
    
    &:hover {
      color: #0091b5;
      box-shadow: 0 2px 8px -2px rgba(0,0,0,0.05), 0 1px 4px -1px rgba(25,15,15,0.4), 0 0 1px 0 rgba(0,0,0,0.6);
    }
  }

  .verticalDivider {
    position: absolute;
    left: -6px;
    top: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(to top, transparent 25%, #E6F1F3 50%, transparent 75%);
  }
}

@media only screen and (max-width: 768px) {
  .closeIcon {
    display: block;
    position: absolute;
    top: 50px;
    left: -18px;
    z-index: 1;
    background: white;
  }
}