@import 'src/shared/styles/_variables.scss';

.layout {
  min-height: 100vh;

  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.05);

  a:active,
  a:focus {
    text-decoration: none;
  }
}

.logoBreadcrumbs {
  display: flex;
  align-items: center;
  transition: all 1s;

  .smallLogo {
    margin-right: 16px;
  }

  .logo {
    width: 233px;
  }

  .breadcrumbs {
    height: 100%;
    border-left: 1px solid $neutral300;
    padding-left: 16px;

    nav {
      line-height: 1;
    }

    ol {
      align-items: baseline;
    }
  }
}

.siderList {
  a {
    text-decoration: none !important;
  }
}

.siderList *[role="menuitem"]:hover {
  background-color: $primary600 !important;
  color: $white !important;

  .siderActiveItem {
    color: $white;
  }
}

.siderList div[aria-expanded="true"] {
  background-color: $primary400 !important;
}

.siderList ul[role="menu"] {
  background-color: $neutral700 !important;
  color: $white !important;
}

ul[role="group"] ul[data-menu-list="true"] {
  background-color: $neutral900 !important;
  margin-top: -4px;
  margin-left: 4px;
  margin-right: 4px;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.siderActiveItem {
  color: $primary400;
}

.menuFooter {
  padding-top: 10px;
  margin-top: auto;
  text-align: center;
}

.linksInMenu {
  text-decoration: none !important;
  color: $white !important;

  &:hover {
    color: $white !important;
  }
}