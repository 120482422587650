@import 'src/shared/styles/_variables.scss';

.tabContent {
  padding: 0 24px 12px;
}

.tabs :global {
  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav>div:nth-of-type(1) {
    width: 100% !important;
  }

  .ant-tabs-nav-list {
    width: 100% !important;
  }
}

.userId {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prefs {
  width: calc(25% - 32px);
  border: 1px solid #b3bfc6;
  border-radius: 2px;
  padding-top: 6px;
  min-width: 300px;

  @media only screen and (max-width: 1750px) {
    width: calc(30% - 32px);
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #132b3a;
  }

  &__block {
    margin-bottom: 12px;
  }

  &__divider {
    padding: 0;
    margin: 25px 0;
    border-top: 1px solid #d5dbdf;
  }

  textarea {
    padding: 0;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #132b3a;
    resize: none;
    border: 1px solid transparent;
  }

  &__select {
    font-size: 16px;
  }

  &__address {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;

    svg {
      font-size: 18px;
      color: #50626c;
      margin-right: 10px;
    }

    textarea[disabled] {
      color: #132b3a;
      cursor: default;
      height: 85px;
    }
  }

  &__dropdown div {
    font-size: 16px;
  }

  &__other p {
    margin-bottom: 12px;
  }

  // > div:last-child {
  //     display: none;
  // }

  &__item {
    margin-bottom: 12px;

    label {
      margin-left: 10px;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: #70838e;
    }
  }

  &__icon {
    display: inline-block;
    margin-left: 10px;
    font-size: 20px;

    &_hasData {
      color: #38a306;
    }

    &_notHasData {
      color: red;
    }
  }

  &__upload {
    svg {
      font-size: 17px;
    }

    a {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: #0091b5;
    }
  }

  &__radio {
    div>div:first-child {
      margin-bottom: 14px;
    }

    label span {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: #132b3a;

      &:first-child,
      &:first-child input[type='radio'],
      &:first-child>span {
        width: 20px;
        height: 20px;
      }

      &:first-child>span::after {
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 20px;
      }
    }
  }
}

.prefs__other p>span:first-child,
.label,
label {
  font-size: 16px;
  margin-right: 10px;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
  color: #70838e !important;
  border: none;
  background-color: transparent;
  text-transform: capitalize;
}

.prefs__other p>span:last-child,
.textValue {
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #132b3a;
  letter-spacing: 0.2px;
}

.updatedAtContainer {
  margin: 8px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

@container article (max-width: 1050px) {

  .prefs {
    width: 100%;
    margin-top: 12px;
  }
}