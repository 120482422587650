.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    padding: 6px 24px;
  }
}
.form {
  margin-top: 16px;
  .alertInfo {
    margin-bottom: 18px;
    .paymentType {
      font-weight: 600;
    }
  }
}