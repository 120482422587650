.paramsBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 12px;
  }

  li {
    display: flex;
    align-items: center;
    background: #c3e0e5;
    padding: 5px 10px;
    height: 30px;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    color: #3f505a;
  }

  .paramTitle {
    text-transform: capitalize;
    margin-right: 3px;
  }

  .paramLabel {
    height: 30px;
    line-height: 30px;
  }

  .paramBtn {
    width: auto;
    height: 20px;
    padding: 0;
    margin-left: 10px;
    line-height: 20px;
    border: none;

    svg {
      font-size: 12px;
    }
  }
}