.form {
  label {
    display: block;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #50626c;
    margin-bottom: 10px;
  }

  input {
    height: 40px;
    border: 1px solid #b3bfc6;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;

    > div:first-child {
      margin: 0;

      button {
        padding: 0;
      }
    }

    button {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.2px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    > div {
      margin: 0;
    }

    > div:first-child {
      margin-right: 8px;
    }

    button {
      height: 40px;
    }

    &_reject {
      padding: 6px 27px;
      color: #3f505a;
      border: 1px solid #94a5ae;

      &:hover,
      &:active,
      &:focus {
        color: #3f505a;
        border: 1px solid #94a5ae;
      }
    }

    &_approve {
      color: #ffffff;
      padding: 6px 34px;
      background: #38a306;
      border: 1px solid #38a306;

      &:hover,
      &:active,
      &:focus {
        color: #ffffff;
        background: #38a306;
        border-color: #38a306;
      }
    }
  }
}

.footer {
  width: 100%;
  justify-content: flex-end;

  button {
    height: 36px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
}
