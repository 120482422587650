.formHeader {
  margin-bottom: 12px;

  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.article {
  container-type: inline-size;
  container-name: article;

  .formContent {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__left {
    width: 75%;

    @media only screen and (max-width: 1750px) {
      width: 70%;
    }
  }

  @container article (max-width: 1050px) {
    .formContent {
      flex-direction: column;
    }

    .article__left {
      width: 100%;
    }
  }
}

.form__btns {
  position: absolute;
  top: -53px;
  right: 40px;
}

.cancelBtn {
  margin-left: 15px;
}

.alert {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10000;
}