.btn {
  display: flex;
  align-items: center;
}

.link {
  display: inline-flex;
  align-items: center;
  color: #0091b5;
  cursor: pointer;
}

.inputNum {
  width: 100%;
}

.item {
  margin-bottom: 24px;
}