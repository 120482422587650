.iconFilter {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.25);
}

.header {
  cursor: pointer;
  height: 24px;
  margin-bottom: 20px;
}

.title {
  margin: 0 8px;
  font-weight: 600;
  font-size: 16px;
  color: #132b3a;
}

.iconArrow {
  font-size: 12px;
  color: #50626c;
}

.filterTitle {
  display: inline-block;
  padding-left: 16px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #132b3a;
}

.filterList {
  width: 227px;
  padding: 20px 0 15px;
}

.filterGroup {
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}

.filterBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  span {
    font-weight: 400;
    font-size: 16px;
    color: #3f505a;
  }
}

.filterJobs {
  font-weight: 600;
  font-size: 16px;
  color: #70838e;
}

.filterApplyBtn {
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-top: 10px;
  font-weight: 600;
}

.jobMapContainer {
  height: 450px;
}
