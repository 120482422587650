.content {
  position: relative;
  min-width: 200px;
}

.treeWrapper {
  overflow: auto;
  max-height: calc(100vh - 425px);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(213, 219, 223, 0.5);
    border-radius: 6px;
  }
}

.isDeletedFooter {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #f5f5f5;
}

.listFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 12px;
  border-top: 1px solid #f5f5f5;
}

.button {
  color: #132b3a;
  width: 32px;
  padding: 0;
  font-size: 18px;
}

.selectLimit {
  margin-top: 20px;
}