.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 12px;
  border-bottom: 1px solid #D5DBDF;
  box-sizing: border-box;

  * {
    font-size: 14px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  &__icons {
    display: flex;
    gap: 5px;
    button {
      width: 36px;
      height: 36px;
    }
    svg {
      font-size: 20px;
      color: #50626C;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-left: 15px;

    > p {
      display: flex;
      align-items: center;
      gap: 3px;
      font-weight: 400;
      font-size: 16px;
      white-space: nowrap;
      color: #132B3A;
    }

    @media only screen and (max-width: 768px) {
      margin-left: 0;
      gap: 0;
    }
  }

  &__label {
    color: #70838E;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__closeIcon {
    width: 24px !important;
    height: 24px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    svg {
      font-size: 20px;
      color: #50626C;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: none !important;
    }
  }

  &__arrow {
    display: flex;

    @media only screen and (max-width: 768px) {
      position: fixed;
      right: 20px;
      bottom: 20px;
      z-index: 2;
    }

    button {
      width: 40px;
      color: #ebf0f3;
      background: #132B3A;
      border: none;
      border-radius: 0;

      &:hover {
        color: #ebf0f3 !important;
        border: none !important;
        background: #50626c !important;
      }
    }

    &_number {
      height: 40px;
      padding: 0 4px;
      line-height: 40px;
      color: #ebf0f3;
      background: #132B3A;
    }
  }
  
  .dNone {
    display: none;
  }

  &__exif {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    * {
      display: flex;
      gap: 2px;
      white-space: nowrap;
    }
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
}

.popoverReject {
  z-index: 10030;
}