.drawer {
  > div > div { //ant drawer-body
    padding: 0 !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    border-bottom: 1px solid #D5DBDF;

    > div:last-child button {
      height: 36px;
      padding: 4px 16px;
      font-weight: 600;
      font-size: 14px;
      margin-left: 8px;
    }
  }

  &__title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  &__close {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0 10px 0 0;
    font-size: 20px;
    line-height: 20px;
    svg {
      font-size: 20px;
      color: #50626C;
    }
  }

  &__content {
    padding: 10px 16px;
  }

  &__collapse {
    > div {
      border-bottom: 1px solid #D5DBDF !important;
    }

    &_header {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #132B3A;
    }

    &_item {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    }

    &_label {
      display: inline-block;
      margin-right: 5px;
      color: #70838E;
    }

    &_value {
      color: #132B3A;
    }
  }

  &__innerContent {
    padding: 16px 24px;
  }

  &__alert {
    padding: 10px 16px;
    margin-bottom: 24px;
    background: #FFF6E3;
    border-radius: 4px;
    > div > div {
      font-weight: 400;
      font-size: 14px;
      color: #3F505A;
    }
  }
}

.commentBlock {
  display: flex;
  align-items: flex-end;
  height: 160px;
  padding: 12px;
  background: #EBF0F3;
  border-radius: 2px;

  textarea {
    height: 100%;
    margin-right: 10px;
    resize: none;
    background: transparent;
    border: none;

    &:focus {
      outline: 1px solid #b3bfc6;
      border-color: #b3bfc6;
      box-shadow: none;
      transition: none;
    }
  }
}

.extraData {
  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  &__label {
    display: inline-block;
    margin-right: 5px;
    color: #3F505A;
  }

  &__value {
    color: #132B3A;
  }
}