.carousel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  // overflow-x: scroll;
  overflow: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;

  &__slide {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    background-color: #fff;
  }

  &__block {
    display: flex;
    overflow: auto;
    height: calc(100% - 65px);
  }
  
  &__tools {
    position: fixed;
    bottom: 32px;
    width: 36px;
    height: 72px;
    margin-left: 24px;
    background: #FAFCFD;
    border: 1px solid #EBF0F3;
    box-shadow: 0px 4px 22px rgba(19, 43, 58, 0.15);
    border-radius: 2px;
    z-index: 3;

    button {
      width: 36px !important;
      height: 36px;
      background-color: transparent;
      border: none;
    }

    .line {
      display: block;
      height: 1px;
      padding: 0 3px;
      background: #D5DBDF;
    }
  }
}

