$primary20: #f3f7f9;
$primary50: #e6f1f3;
$primary100: #c3e0e5;
$primary400: #4ebccf;
$primary500: #03a3bf;
$primary600: #0091b5;
$primary700: #007d9c;

$secondary400: #ea5972;
$secondary600: #cb2e49;
$secondary500: #dd3552;
$secondary700: #bd223d;

$neutral0: #ffffff;
$neutral50: #ebf0f3;
$neutral100: #d5dbdf;
$neutral300: #b3bfc6;
$neutral400: #94a5ae;
$neutral500: #70838e;
$neutral600: #50626c;
$neutral700: #3f505a;
$neutral900: #132b3a;

$error100: #ff9c94;
$error500: #fc594c;
$error700: #cb1b16;

$red500: #c9342d;
$red700: #a11f02;
$green500: #38a306;
$green700: #2e8206;
$yellow500: #f7941d;
$yellow700: #d67500;
$yellow800: #c16a00;
$white: #fff;

$googleMapGrey: #e5e3df;
