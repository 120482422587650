.header {
  padding: 16px;
  border: 1px solid #D5DBDF;
  border-radius: 2px;

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__title {
    width: 50%;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &_disabled {
      display: flex;
      align-items: center;
      color: #B3BFC6;
    }
  }

  &__tag {
    padding: 2px 10px;
    margin-left: 15px;
    font-weight: 700;
    font-size: 12px;
    color: #FC594C;
    background: #fff;
    border: 1px solid #FC594C;
    border-radius: 2px;
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #70838E;

    &[data-payout-status="notPaid"],
    &[data-payout-status="66c8cb80-6c3f-4ef8-b44d-0bb122115d99"],
    &[data-payout-status="6bb5e222-89c6-4e34-b80c-8fa44537d991"] {
      &::before {
        content: " ";
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 8px;
        margin-bottom: 2px;
        border-radius: 50%;
        background: #FC594C;
      }
    }

    &[data-payout-status="paid"]::before,
    &[data-payout-status="6d9f458b-07a8-407f-849a-7deb166e2bfa"]::before {
      content: " ";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      margin-bottom: 2px;
      border-radius: 50%;
      background: #38a306;
    }

    &[data-payout-status="6693ac79-533a-4e44-b9ba-518610de22dc"]::before {
      content: " ";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      margin-bottom: 2px;
      border-radius: 50%;
      background: #EDBC0F;
    }

  }

  &__value {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #132B3A;
  }

  &__valueLight {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #B3BFC6;
  }

  &__blackBtn {
    height: 24px;
    padding: 0px 8px;
    font-weight: 700;
    font-size: 12px;
    color: #EBF0F3;
    background: #132B3A;
  }

  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;

    >div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__divider {
    margin: 10px 0 7px 0;
    border-top: 1px solid #D5DBDF;
  }
}

.tooltip div {
  font-size: 14px;
  height: auto;
  min-height: auto !important;
}

.smallStatus {
  padding: 4px;
  width: auto;
  max-width: unset;
  min-width: unset;
  line-height: 12px;
  border: 0;
}

.updatedPrice {
  color: #38a306;
}

.extLink {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.tooltipPurchase {
  display: flex;
  justify-content: space-between;
}

.propertyData {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amountsOfInvoice {
  display: flex;
  column-gap: 16px;
  flex-wrap: wrap;
}