.map {
  &__title {
    padding: 24px 0 10px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #132B3A;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 24px;

    >div {
      flex-grow: 1;
      margin-bottom: 0;

      &:last-child {
        flex-basis: 100%;
      }
    }

    label {
      font-weight: 400;
      font-size: 14px;
      color: #3F505A;
    }
  }

  &__blockStatic {
    position: relative;
  }

  &__blockDynamic {
    position: relative;
    width: 100%;
    height: 350px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &__link {
    border: 1px solid #D5DBDF;
    background: #fff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
    &:hover {
      background: #fff !important;
    }
  }
}

.latLngInputs {
  width: 100%;
}

.markerIcon {
  margin-bottom: 10px;
}

.markerIcon::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
}

.checkInBg::before {
  background-color: green;
}

.checkOutBg::before {
  background-color: blue;
}

.mainMarkerBg::before {
  background-color: red; 
}

.mapBlock {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  height: 100%;
}