.inputNum {
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
}

.txBlock {
  order: 1;
}

.txData {
  padding: 7px 11px;
  margin-top: 8px;
  background-color: rgba(0, 0, 0, 0.04);
}