.urls {
  padding: 0 24px 16px 24px;
  &__label {
    font-weight: 600;
    font-size: 16px;
    color: #132B3A;
    margin: 16px 0 6px;
  }
  &__value {
    font-weight: 400;
    font-size: 16px;
    color: #50626C;
  }
}