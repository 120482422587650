.customTable {
  .ant-pro-table-alert {
    display: none !important;
  }
}

.customTable thead > tr {
  > td {
    background: #c3e0e5 !important;
  }

  > th {
    background: #c3e0e5 !important;
    height: 64px;
    // padding: 0 16px !important;
    vertical-align: middle;
    font-family: 'Lato';
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    color: #132b3a;
  }
}

.customTable tbody>tr>td>a {
  font-size: 16px !important;
}

.customTable tbody>tr>td>b {
  font-weight: bold;
}

.customTable tr>td {
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // text-align: center;
}

.customTable>div>div>div:last-child>div>div>div>div>div:last-child {
  overflow-y: auto !important;
}

.csvBtn {
  display: flex;
  align-items: center;
  padding: 0;
}

.loader {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subTitle {
  font-weight: 400;
  font-size: 14px;
  color: #3f505a;
}

.subTitleBold {
  font-weight: 600;
}

.headerBlock {
  display: flex;
  align-items: center;
  gap: 10px;
}



.dropdownIcon svg {
  font-size: 14px;
  color: #70838e;
}

.selectAllLoader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  z-index: 222;
  background: rgba(5, 5, 5, 0.1);
}