.form {
  position: relative;

  input {
    font-size: 16px !important;
    font-weight: 400;
  }
}

.inputLong {
  input {
    width: 100% !important;
  }
}

.formItem {
  font-size: 16px;
  margin-bottom: 15px;
}

.formItem label {
  width: 160px;
  font-weight: 600;
  text-transform: capitalize;
}

.readonly:disabled {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  cursor: initial;
}

.dropdownBtn {
  position: absolute;
  top: 35px;
  right: 25px;

  svg {
    font-size: 18px;
  }
}

.menuItem {
  font-size: 16px;
}

.btnBlock {
  display: flex;
  justify-content: flex-end;
  button {
    margin-bottom: 20px;
  }
}

.formItems {
  width: 60%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

input:disabled,
textarea:disabled {
  color: #3F505A !important;
}