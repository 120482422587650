.main_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff !important;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1000;
}

.profile_block {
  display: flex;
  align-items: center;

  &__name {
    margin: 0 16px 0 0;
    line-height: auto;
    color: #3f505a;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;

    span {
      font-weight: 600;
    }
  }

  &__avatar {
    width: 36px;
    height: 36px;
    line-height: 36px;
  }
}

.dropdownButton {
  padding: 0;
  height: 100%;
}
