.link {
  color: #0091b5;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expandIcon {
  border: 1px solid #f0f0f0;
}

.operationType {
  text-transform: capitalize;
}