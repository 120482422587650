@import 'src/shared/styles/_variables.scss';

.link {
  color: $primary600;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}