.com {
  &__header {
    display: flex;
    // justify-content: space-between;
    padding: 20px 24px;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 24px;
    border-radius: 2px 2px 0px 0px;
    border: 1px solid #B3BFC6;
    background: #C3E0E5;
  }

  &__bigWidth {
    width: 48%;
  }

  &__smallWidth {
    width: 4%;
  }

  &__collapse {
    background: transparent;
  }

  &__panel {
    // panel header
    > div {
      padding: 20px 24px !important;

      // expand icon
      > div {
        padding: 0 !important;
      }
    }
  }

  &__headerPanel {
    display: flex;
  }
}

.fixedHeightCom {
  height: auto !important;
  max-height: 350px !important;
  overflow: auto;
}

.threeDots {
  display: -webkit-box;
  max-width: 90%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
