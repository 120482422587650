.wrapper {
  padding: 8px;
  // position: fixed;
  min-width: 250px;
  width: fit-content;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.rangeAction {
  display: flex;
  justify-content: flex-end;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.resetButton {
  padding: 0 !important;
}

.rangePickerDropdown {
  div.ant-picker-cell-inner {
    height: 24px !important;
  }
}