.img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ebf0f3;
  cursor: pointer;
}

.imgInModal {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  width: auto;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.imgBlock {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100px;
  overflow: auto;
  background-color: #ebf0f3;
}

.block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader {
  background: #EBF0F3;
  // border: 1px solid #B3BFC6;
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #50626C;
    margin-top: 15px;
  }
}

.error {
  background: rgba(255, 202, 198, 0.3);
  border: 1px solid #FC594C;
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #FC594C;
    margin-top: 15px;
  }
  svg {
    font-size: 36px;
    fill: #FC594C;
  }
}

.dBlock {
  display: block;
}

.dNone {
  display: none;
}