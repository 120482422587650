@import 'variables';
@import 'statuses';

//Button
.btn {
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.2px;
  border-radius: 2px;
}

.disable__btn {
  color: #b3bfc6;
  background: #ebf0f3;
  border: 1px solid #b3bfc6;
}

.link__btn {
  color: #0091b5;
}

.btn.primary__btn {
  color: #fff;
  background: #03a3bf;
  border-color: #03a3bf;

  &:hover {
    color: #ffffff;
    background: #0091b5;
    border-color: #0091b5;
  }

  &:active {
    color: #ffffff;
    background: #007d9c;
    border-color: #007d9c;
  }

  &[disabled] {
    color: #b3bfc6;
    background: #ebf0f3;
    border: 1px solid #b3bfc6;
  }
}

.btn.secondary__btn {
  color: #3f505a;
  background: #fff;
  border: 1px solid #94a5ae;

  &:hover {
    color: #03a3bf;
    background: #f3f7f9;
    border: 1px solid #03a3bf;
    box-shadow: 0px 0px 4px #48c5db;
  }

  &:active {
    color: #007d9c;
    background: #e6f1f3;
    border: 1px solid #007d9c;
    box-shadow: none;
  }

  &[disabled] {
    color: #94a5ae;
    background: #ebf0f3;
    border: 1px solid #b3bfc6;
  }
}

.outline__btn {
  border-color: #03a3bf;
  color: #03a3bf;
  background: #fff;
}

.btn.success__btn {
  color: #fff;
  background: $green500;

  &:active,
  &:focus,
  &:hover:not(:disabled) {
    color: $white !important;
    background: $green700 !important;
    border-color: $green700 !important;
  }

  &[disabled] {
    color: #94a5ae;
    background: #ebf0f3;
    border: 1px solid #b3bfc6;
  }
}

.btn.reject__btn {
  color: $error700;
  background: rgba($color: $error500, $alpha: 0.2);
  border-color: $error100;

  &:active,
  &:focus,
  &:hover {
    color: $error700 !important;
    background: $error100;
    border-color: $error500;
  }

  &[disabled] {
    color: #94a5ae;
    background: #ebf0f3;
    border: 1px solid #b3bfc6;
  }
}

.btn.reject__btn_disabled {
  color: #B3BFC6;
  background: #EBF0F3;
  border-color: #B3BFC6;

  &:active,
  &:focus,
  &:hover {
    color: #B3BFC6;
    background: #EBF0F3;
    border-color: #B3BFC6;
  }
}

.btn.gray__btn {
  color: $neutral700;
  background: rgba($color: $neutral500, $alpha: 0.2);
  border-color: $neutral100;

  &:active,
  &:focus,
  &:hover {
    color: $neutral700;
    background: $neutral100;
    border-color: $neutral500;
  }

  &[disabled] {
    color: #94a5ae;
    background: #ebf0f3;
    border: 1px solid #b3bfc6;
  }
}

//STATUSES FOR ORDERS
.orderStatus {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;

  display: inline-flex;
  justify-content: center;

  padding: 7px 16px;

  max-width: 100%;
  min-width: 120px;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  border-radius: 2px;
}

[data-order-status] {
  color: #3f505a;
  background: rgb(243, 239, 235);
}

[data-order-status='#{$DRAFT_ORDER}'] {
  color: #3f505a;
  background: #f3efeb;
}

[data-order-status='#{$TEMPLATE_ORDER}'] {
  color: #3f505a;
  background: #d5dbdf;
}

[data-order-status='#{$SUBMITTED_ORDER}'] {
  color: #c16a00;
  background: #fdead2;
}

[data-order-status='#{$PENDING_ORDER}'],
[data-order-status='#{$AUTHORIZE_PENDING}'] {
  color: #007d9c;
  background: #dcf2f5;
}

[data-order-status='#{$REJECTED_ORDER}'] {
  color: #cb1b16;
  background: #fae8e8;
}

[data-order-status='#{$ACCEPTED_ORDER}'] {
  color: #2e8206;
  background: #e2f5da;
}

[data-order-status='#{$PUBLISHED_ORDER}'],
[data-order-status='#{$ACCEPTED_ORDER}'] {
  color: #ffffff;
  background: #52a436;
}

[data-order-status='#{$COMPLETED_ORDER}'] {
  color: #ffffff;
  background: #4ebccf;
}

[data-order-status='#{$EXPIRED_ORDER}'] {
  color: #bd223d;
  background: #f7cdd4;
}

[data-order-status='#{$CANCELLED_ORDER}'] {
  color: #ffffff;
  background: #b3bfc6;
}

//STATUSES FOR Jobs
.jobStatus {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  display: inline-flex;
  justify-content: center;

  padding: 7px 16px;

  max-width: 100%;
  min-width: 110px;

  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  border-radius: 2px;
}

[data-job-status='#{$ACCEPTED_JOB}'] {
  color: #c16a00;
  background: #fdead2;
}

[data-job-status='#{$PUBLISHED_ORDER}'] {
  color: #2e8206;
  background: #e2f5da;
}

[data-job-status='#{$EXPIRED_JOB}'] {
  color: #bd223d;
  background: #f7cdd4;
}

[data-job-status='#{$SUBMITTED_JOB}'] {
  color: #ffffff;
  background: #f7941d;
}

[data-job-status='#{$PENDING_JOB}'] {
  color: #007d9c;
  background: #dcf2f5;
}

[data-job-status='#{$COMPLETED_JOB}'] {
  color: #ffffff;
  background: #52a436;
}

[data-job-status='#{$REJECTED_JOB}'] {
  color: #cb1b16;
  background: #fae8e8;
}

[data-job-status='#{$DECLINED_JOB}'] {
  color: #ffffff;
  background: #ff9c94;
}

[data-job-status='#{$RETURNED_JOB}'] {
  color: #fff;
  background: #c3e0e5;
}

[data-job-status='#{$CANCELLED_JOB}'] {
  color: #fff;
  background: #b3bfc6;
}

[data-job-status='Deleted'] {
  color: #fc594c;
  background: rgba(252, 89, 76, 0.15);
  border: 1px solid #fc594c;
}

//STATUSES FOR Fulfilment Content
.contentStatus {
  padding: 2px 10px;
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.03em;
  border-radius: 2px;
  border: none;
}

[data-content-status='#{$PROCESSING_CONTENT}'] {
  color: #007d9c;
  background: #dcf2f5;
}

[data-content-status='#{$PROCESSED_CONTENT}'] {
  color: #ffffff;
  background: #4ebccf;
}

[data-content-status='#{$SUBMITTED_CONTENT}'] {
  color: #c16a00;
  background: #fdead2;
}

[data-content-status='#{$FAILED_CONTENT}'] {
  color: #ffffff;
  background: #ff9c94;
}

[data-content-status='#{$APPROVED_CONTENT}'] {
  color: #2e8206;
  background: #e2f5da;
}

[data-content-status='#{$REJECTED_CONTENT}'],
[data-content-status='#{$DUPLICATED_CONTENT}'],
[data-content-status='#{$OUT_OF_FOCUSED_CONTENT}'] {
  color: #cb1b16;
  background: #fae8e8;
}


[data-content-status='#{$DELIVERED_CONTENT}'] {
  color: #ffffff;
  background: #52a436;
}

[data-content-status='Deleted'] {
  color: #fc594c;
  background: rgba(252, 89, 76, 0.15);
  border: 1px solid #fc594c;
  border-radius: 2px;
}

//STATUSES for Purchases

.purchaseOrInvoiceStatus {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;

  display: inline-flex;
  justify-content: center;

  padding: 7px 12px;
  margin: 0;

  max-width: 100%;
  min-width: 120px;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  border: none;
  border-radius: 2px;
}

[data-purchase-id], [data-invoice-id] {
  color: #3f505a;
  background: #f3efeb;
}

//Invoice Draft
[data-invoice-id='#{$DRAFT_INVOICE}'] {
  color: #3f505a;
  background: #f3efeb;
}

//Not Paid
[data-purchase-id='#{$NOT_PAID_PURCHASE}'],
[data-invoice-id='#{$NOT_PAID_INVOICE}'] { 
  color: #c16a00;
  background: #fdead2;
}

//Pending
[data-purchase-id='#{$PENDING_PURCHASE}'],
[data-invoice-id='#{$AUTHORIZE_PENDING_INVOICE}'],
[data-invoice-id='#{$PAYMENT_PENDING_INVOICE}'],
[data-invoice-id='#{$REFUND_PENDING_INVOICE}'] { 
  color: #ffffff;
  background: #4ebccf;
}

//Payed
[data-purchase-id='#{$PAID_PURCHASE}'],
[data-invoice-id='#{$PAID_INVOICE}'],
[data-invoice-id='#{$REFUNDED_INVOICE}'] { 
  color: #ffffff;
  background: #52a436;
}

//Partial payed
[data-purchase-id='#{$PARTIAL_PAID_PURCHASE}'],
[data-invoice-id='#{$PARTIAL_PAID_INVOICE}'],
[data-invoice-id='#{$AUTHORIZED_INVOICE}'] {
  color: #2e8206;
  background: #e2f5da;
}

//Cancelled
[data-purchase-id='#{$CANCELLED_PURCHASE}'],
[data-invoice-id='#{$CANCELLED_INVOICE}'] {
  color: #cb1b16;
  background: #fae8e8;
}

//Failed
[data-purchase-id='#{$FAILED_PURCHASE}'],
[data-invoice-id='#{$AUTHORIZED_FAILED_INVOICE}'],
[data-invoice-id='#{$PAYMENT_FAILED_INVOICE}'],
[data-invoice-id='#{$REFUND_FAILED_INVOICE}'] {
  color: #bd223d;
  background: #f7cdd4;
}

//STATUSES for Payment Operations

.payOperationsStatus {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;

  display: inline-flex;
  justify-content: center;

  padding: 7px 12px;
  margin: 0;

  max-width: 100%;
  min-width: 120px;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  border: none;
  border-radius: 2px;
}

[data-payoperationstatus-id] {
  color: #3f505a;
  background: rgb(243, 239, 235);
}

//Verifying
[data-payoperationstatus-id='#{$VERIFYING_PAY_OPERATIONS}'] { 
  color: #c16a00;
  background: #fdead2;
}

//Authorize pending
//Capture pending
//Refund pending
[data-payoperationstatus-id='#{$AUTHORIZE_PENDING_PAY_OPERATIONS}'],
[data-payoperationstatus-id='#{$REFUND_PENDING_PAY_OPERATIONS}'] {
  color: #ffffff;
  background: #4ebccf;
}

//Hold
//Settled
[data-payoperationstatus-id='#{$HOLD_PAY_OPERATIONS}'],
[data-payoperationstatus-id='#{$SETTLED_PAY_OPERATIONS}'] {
  color: #ffffff;
  background: #52a436;
}

//Refunded
//Verified
[data-payoperationstatus-id='#{$REFUNDED_PAY_OPERATIONS}'],
[data-payoperationstatus-id='#{$VERIFIED_PAY_OPERATIONS}'] {
  color: #2e8206;
  background: #e2f5da;
}

//Cancelled
//Failed
//Not verified
//Refund failed
[data-payoperationstatus-id='#{$CANCELLED_PAY_OPERATIONS}'],
[data-payoperationstatus-id='#{$FAILED_PAY_OPERATIONS}'],
[data-payoperationstatus-id='#{$NOT_VERIFIED_PAY_OPERATIONS}'],
[data-payoperationstatus-id='#{$REFUND_FAILED_PAY_OPERATIONS}']  {
  color: #cb1b16;
  background: #fae8e8;
}

//Declined hold
//Declined settle
//Expired
//Gateway rejected
[data-payoperationstatus-id='#{$DECLINED_HOLD_PAY_OPERATIONS}'],
[data-payoperationstatus-id='#{$DECLINED_SETTLE_PAY_OPERATIONS}'],
[data-payoperationstatus-id='#{$EXPIRED_PAY_OPERATIONS}'],
[data-payoperationstatus-id='#{$GATEWAY_REJECTED_PAY_OPERATIONS}'] {
  color: #bd223d;
  background: #f7cdd4;
}