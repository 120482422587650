@import 'src/shared/styles/_variables.scss';

.address {
  display: flex;
  align-items: center;
}

.tag {
  color: $primary700;
}
