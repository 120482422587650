@import 'src/shared/styles/_variables.scss';

:global(.gm-style .gm-style-iw-c) {
  min-width: 190px !important;
  border-radius: 2px !important;
  border: none;
  padding: 0 !important;
}
:global(.gm-style-iw-d) {
  overflow: auto !important;
}
:global(.gm-ui-hover-effect) {
  display: none !important;
}
:global(.gm-style-iw-tc)::after {
  display: none;
}
:global(.gm-style-iw-t) {
  margin-bottom: 40px;
}

.nameText {
  user-select: text;
  color: $neutral900;
  font-weight: 800;
  font-size: 16px;
  line-height: 150%;

  &:hover {
    cursor: text;
  }
}

.phoneContainer {
  display: flex;
  gap: 6px;
  color: $neutral600;
}
.phoneText {
  user-select: text;
  color: $neutral600;
  font-family: 'Lato';
  font-weight: 600;

  &:hover {
    cursor: text;
  }
}
