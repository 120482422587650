.addressForm {
  input {
    height: 40px;
    font-size: 16px;
    color: #132b3a;
    letter-spacing: 0.2px;
  }

  label[title] {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #50626c;
  }
}

.footer {
  width: 100%;
  justify-content: flex-end;

  button {
    height: 36px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
}

.formBlock {
  margin-bottom: 15px;
}
