.memo {
  &__title {
    padding: 24px 0 10px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #132B3A;
  }

  &__formItem {
    margin-bottom: 0;
  }
}