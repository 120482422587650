.dataTitle {
  text-transform: capitalize;
}

.orderType {
  svg {
    font-size: 18px;
    color: #007d9c;
    margin-right: 8px;
  }
}