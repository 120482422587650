.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &__actions {
    display: flex;
    align-items: center;
  }

  &__title {
    width: 50%;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &_disabled {
      display: flex;
      align-items: center;
      color: #B3BFC6;
    }
  }

  &__tag {
    padding: 2px 10px;
    margin-left: 15px;
    font-weight: 700;
    font-size: 12px;
    color: #FC594C;
    background: #fff;
    border: 1px solid #FC594C;
    border-radius: 2px;
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #70838E;
  }

  &__value {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #132B3A;
  }

  &__blackBtn {
    height: 24px;
    padding: 0px 8px;
    font-weight: 700;
    font-size: 12px;
    color: #EBF0F3;
    background: #132B3A;
  }

  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  [data-payout-status="notPaid"]::before {
    content: " ";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    margin-bottom: 2px;
    border-radius: 50%;
    background: #FC594C;
  }

  [data-payout-status="paid"]::before {
    content: " ";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    margin-bottom: 2px;
    border-radius: 50%;
    background: #38a306;
  }

  &__divider {
    margin: 10px 0 7px 0;
    border-top: 1px solid #D5DBDF;
  }
}

.tooltip div {
  font-size: 14px;
  height: auto;
  min-height: auto !important;
}