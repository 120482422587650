.showMoreButton {
  padding: 7px 8px;
}

.showMoreButtonIcon {
  font-size: 24px;
}

.header {
  &__container {
    display: flex;
    width: 100%;
  }

  &__user {
    position: relative;
    top: -12px;
    justify-content: flex-end;
  }

  &__edit {
    position: relative;
  }

  &__btn {
    display: flex;
    align-items: center;
    padding: 0;
    background: transparent;

    &:active,
    &:hover,
    &:focus {
      background: transparent;
    }

    svg {
      font-size: 20px;
    }
  }

  &__menu {
    position: absolute;
    right: 0;
    width: 189px;
    padding: 8px 0;
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(19, 43, 58, 0.15);
    border-radius: 2px;
    z-index: 1;

    &_item {
      width: 100%;
      height: 40px;
      margin: 0;
      text-align: left;
      font-size: 16px;

      & span {
        font-weight: 400;
      }

      &:hover {
        background: #e6f1f3;
      }
    }
  }

  &__block {
    width: 200px;
  }
}
