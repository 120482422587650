.blockDrawer {
  display: flex;
  justify-content: flex-end;
  &__btnDrawer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    background: #FFFFFF;
    border: 1px solid #D5DBDF;
    &:hover {
      background: #FFFFFF !important;
    }
    svg {
      font-size: 20px;
      color: #50626C;
    }
  }
  &__showBtnDrawer {
    top: 81px;
    margin-right: 16px;
    box-shadow: 0px 1px 2px rgba(19, 43, 58, 0.06), 0px 3px 10px rgba(19, 43, 58, 0.09);
  }
  &__closeBtnDrawer {
    margin-top: 32px;
    margin-left: -21px;
    @media only screen and (max-width: 500px) {
      margin-left: 5px;
    }
  }
}