.block {
  display: flex;
  gap: 24px;
  margin-top: 18px;
  border-top: 1px solid #d5dbdf;
  padding-top: 18px;
  justify-content: flex-end;
  margin-bottom: 18px;
}

.blockInnerWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  font-size: 16px;
  color: #3f505a;
}

.textBold {
  font-weight: 700;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  button {
    padding: 6px 24px;
  }
}

.copyIcon {
  margin: 0 10px 0 5px;
  color: #0091B5;
  cursor: copy !important;
}

.optionName {
  padding-right: 8px;
  font-weight: 700;
}