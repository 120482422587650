.completedJobsTable th:first-child {
  padding: 12px 8px !important;
}

.payBtn {
  height: 40px;
  padding: 6px 18px;
  font-size: 16px;
  font-weight: 600;

  &:hover,
  &:focus {
    color: #fff;
  }
}
