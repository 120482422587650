.btn {
  display: flex;
  align-items: center;
}

.link {
  color: #0091b5;
  cursor: pointer;
}

.checkboxes {
  display: flex;
  align-items: center;
  gap: 30px;
}