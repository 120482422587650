.dropdown {
  li {
    padding: 0 !important;
  }

  button {
    text-align: left;
  }
}

.form {
  width: 50%;
  display: flex;
}

.jobPage {
  position: relative;
  height: auto;
  padding: 0;

  >div {
    min-height: calc(100vh - 85px);
  }

  &__main {
    padding: 12px 35px 12px 35px;
  }

  &__tmplMain {
    padding: 12px 35px;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    margin-top: 52px;
  }

  &__contentBlock {
    flex: 1 1;
    // width: 50%;
  }
}

.tabs {
  margin-top: 24px;

  [role="tablist"] {
    margin-bottom: 16px !important;
  }

  >div {
    margin-bottom: 0 !important;

    >div {
      >div {
        & [data-node-key$="Disabled"] {
          display: none !important;
        }

      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .jobPage {
    &__main,
    &__tmplMain {
      padding: 8px 8px 8px 8px;
    }
  }
}