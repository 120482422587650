.cmpl {
  &__title {
    padding: 24px 0 10px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #132B3A;
  }

  &__jobType {
    margin-bottom: 16px;

    div {
      display: inline-flex;
      min-width: 120px;
      height: 28px;
      line-height: 28px;
      flex-direction: row !important;
      flex-flow: column;
      align-items: center;
      padding: 0 !important;
      margin: 0;

      label {
        font-size: 16px !important;
        color: #000 !important;
      }
    }
  }

  &__block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    >div {
      // width: 250px;
      margin: 0;
    }
  }

  &__dateBlock {
    padding-top: 10px;
  }

  &__priceBlock {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__formItem {
    min-width: 300px;
    flex-grow: 1;

    span[class*="label"] {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__label {
    width: 60%;
    white-space: pre-wrap;
  }

  &__price {
    font-size: 14px;
    color: #70838e;

    &_radio {
      display: inline-block;
      margin-left: 5px;
      height: auto;
      line-height: normal;
      white-space: nowrap;
    }

    &_select {
      line-height: 40px;
    }
  }
}

.dates {
  width: 100%;

  input:disabled {
    color: #3f505a !important;
  }
}

.timezone {
  display: flex;
  align-items: center;
  gap: 6px;
}

@media only screen and (max-width: 768px) {
  .cmpl {
    &__jobType {

      div {
        display: block;
        height: unset;
        min-width: unset;
      }
    }
  }
}