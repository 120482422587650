.quill>div>div:first-child {
  padding: 12px;
  font-size: 16px;

  & strong {
    font-weight: 700;
  }

  & em {
    font-style: italic;
  }
}

.jobQuill>div>div:last-child {
  display: none;
}

.quill :global(.ql-clipboard) {
  position: fixed;
}

.quill {
  >div:first-child {
    position: sticky;
    top: 56px;
    z-index: 1;
    background-color: #EBF0F3 !important;
  }
}