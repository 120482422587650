.comments {
  position: fixed;
  right: 35px;
  max-width: 400px;
  width: 100%;
  height: calc(100vh - 150px);
  border-radius: 2px;
  @media only screen and (max-width: 768px) {
    right: 0;
  }

  &__tab,
  &__tab>div:last-child>div,
  &__tab>div:last-child>div>div {
    height: 100%;
  }

  &__tab>div:first-child {
    margin-bottom: 0;
  }

  .title {
    position: relative;
    color: unset;
    font-size: 15px;
  }

  .newMessage::after {
    content: "";
    position: absolute;
    top: 0;
    right: -12px;
    display: inline;
      width: 6px;
      height: 6px;
      background: #d73550;
      border: 2px solid #f08e9f;
      border-radius: 50%;
  }
}

.commentsInner {
  position: relative;
  height: auto;
  width: 100%;
  right: 0;

  >div>div:first-child {
    display: none;
  }
}

.inDrawer {
  position: relative;
  height: 100%;
  width: 99%;
  right: 0;
}
