.newWrapper {
    margin: 12px 0 0;

    .newTagInput {
        width: 100%;
        padding: 0 4px;
        &::placeholder {
            opacity: 0.4;
        }
    }
    
    .newTag {
        border-style: dashed;
        text-transform: none;
    }
}

.tag {
    margin-inline-end: 4px;
    text-transform: capitalize;
}

.tagMore {
    color: #94a5ae !important;
}