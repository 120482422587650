@import 'src/shared/styles/_variables.scss';

// ----------------- RESET STYLES ---------------------------- //
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  font-family: 'Lato' !important;
}

/*HEADER*/
.layout__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
}

.layout__userName {
  margin: 0 16px 0 0;
  line-height: auto;
  color: #3f505a;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}

.layout__userName>span {
  font-weight: 600;
}

.layout__avatar {
  width: 36px;
  height: 36px;
  line-height: 36px;
}

// Ant ProStepForm width

.ant-pro-steps-form-container {
  width: 100%;
}

/**** TABS *****/

.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 16px;
  color: $neutral500;

  &:hover {
    color: $neutral700;
  }

  &:active {
    color: $neutral900;
  }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $neutral900;
  font-weight: 700;
}

.ant-tabs .ant-tabs-ink-bar {
  background: $green500;
}

.ant-tabs-tab-btn,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: $neutral900;
  letter-spacing: 0;
}

/**** ICON PRO TABLE ****/
.ant-pro-table-list-toolbar-container .ant-pro-table-list-toolbar-right {
  flex: unset;
  
  svg[data-icon] {
    font-size: 20px;
  }
}

.ant-pro-card-body {
  padding-top: 30px !important;
}

/****STEPS ***/
.ant-steps-item-finish .ant-steps-item-icon {
  background: #38a306;
  border-color: #38a306;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon,
.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  color: #fff;
}

.ant-steps-item-title::after {
  border-bottom: 2px dashed #b3bfc6;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background: none;
  border-bottom: 2px dashed #38a306;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #fff;
  border-color: #38a306;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
  color: #38a306;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background: #b3bfc6;
  border-color: #b3bfc6;
}

.ant-steps-item-content .ant-steps-item-title {
  font-size: 18px;
}

.ant-steps-item:hover {
  cursor: pointer;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #b3bfc6;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title {
  color: #70838e;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #132b3a;
}

.ant-pro-card-body {
  padding: 0 !important;
}

// RADIO
.notification .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0091b5;
  border-color: #0091b5;
}

.notification .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border: 1px solid #b3bfc6;
}

//SELECT
.levelSelect.ant-select.ant-select-in-form-item .ant-select-selector {
  height: 36px;
}

.levelSelect.ant-select.ant-select-in-form-item .ant-select-selection-item {
  line-height: 36px;
}

.ant-select-item.ant-select-item-option .ant-select-item-option-content {
  font-weight: 400;
  font-size: 16px;
}

.ant-select-item-option.ant-select-item-option-selected .ant-select-item-option-content {
  font-weight: 600;
}

.editableRadioFalse label:not(.ant-radio-wrapper-checked) {
  display: none;
}

.editableRadioFalse label.ant-radio-wrapper-checked>span:first-child {
  display: none;
}

//general override

.info__user_contact {
  .ant-select-selection-item {
    background: #0a3069;
  }
}

//Table expanded tr
.ant-table-expanded-row.ant-table-expanded-row-level-1>td.ant-table-cell {
  height: auto;
  padding: 30px !important;
  text-align: left;
}

// .ant-table-body td.ant-table-cell {
//   padding-left: 16px !important;
//   padding-right: 16px !important;
// }

//Users tab
.usersTab .ant-menu.ant-menu-root {
  border: none;
}

.usersTab .ant-menu-submenu-arrow {
  right: 10px;
  top: 16px;
}

.usersTab .ant-menu.ant-menu-root .ant-menu-submenu-title {
  width: 110px;
  max-width: 110px;
  height: 40px !important;
  padding: 0 20px 11px 0px !important;
  line-height: 40px !important;
  margin: 0;
  border-bottom: 1px solid #d5dbdf;
}

.usersTab .ant-tabs-nav-wrap {
  overflow: visible !important;
  z-index: 2;
}

.usersTab .ant-tabs-tab-btn {
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  color: #3f505a;
  padding-bottom: 11px;
  border-bottom-color: transparent;
}

.usersTab.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 1px solid #d5dbdf;
}

.usersTab .ant-tabs-tab {
  padding: 0;
}

.usersTab .ant-tabs-tab.ant-tabs-tab-disabled {
  cursor: pointer;
}

// .requestMenu {
//   padding-right: 20px;
//   width: 125px !important;
// }

.requestMenu .ant-checkbox-group,
.requestMenu .ant-radio-group {
  display: flex;
  flex-direction: column;
  // position: absolute;
  // top: 0;
}

.ant-radio-button-wrapper-checked > span {
  color: #3F505A;
}

.requestMenu .ant-menu.ant-menu-sub.ant-menu-inline {
  background: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.requestMenu .ant-menu-item.ant-menu-item-only-child {
  height: 100% !important;
  padding: 0 !important;
  overflow: visible;

  &::after {
    display: none;
  }
}

.requestMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}

.requestMenu .ant-checkbox-group>label,
.requestMenu .ant-radio-group>label {
  height: 40px !important;
  line-height: 40px !important;
  padding-left: 10px !important;
  margin-bottom: 8px;

  span {
    font-weight: 400;
    font-size: 14px;
    color: #3f505a;
  }
}

//GENERAL TABLE

//toolbar
.generalTable .ant-pro-table-list-toolbar {
  margin-bottom: 16px;
  overflow: hidden;
}

.generalTable .ant-table {
  border: 1px solid $neutral300;
  border-radius: 2px;
}

.generalTable .ant-pro-table-list-toolbar-container,
.generalTable .ant-pro-table-list-toolbar-extra-line,
.generalTable .ant-pro-table-list-toolbar-extra-line .ant-tabs-tab,
.generalTable .ant-pro-table-list-toolbar-extra-line .ant-tabs-tab.ant-tabs-tab-active {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.generalTable.tabsAndSettings .ant-pro-table-list-toolbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-pro-table-list-toolbar-container {
    width: 100%;
  }
}

//settings change order
.generalTable .ant-pro-table-list-toolbar-setting-items .ant-pro-table-list-toolbar-setting-item:has(.anticon-reload) {
  display: none;
}

.generalTable .ant-pro-table-list-toolbar-setting-items .ant-pro-table-list-toolbar-setting-item:has(.anticon-setting) {
  order: 1;
}

.generalTable .ant-pro-table-list-toolbar-setting-items .ant-pro-table-list-toolbar-setting-item:has(.anticon-fullscreen) {
  order: 2;
}

.generalTable .ant-pro-table-list-toolbar-setting-items .ant-pro-table-list-toolbar-setting-item:has(.anticon-column-height) {
  order: 3;
}

//copy svg in data
.generalTable td span:has(> div.ant-typography-copy) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.generalTable td div.ant-typography-copy {
  margin-right: 5px;
}

//filters and sorters
.generalTable th .ant-table-filter-column svg,
.generalTable th .ant-table-column-sorter {
  color: #70838e;
}

.generalTable th .ant-dropdown-trigger.ant-table-filter-trigger.active svg,
.generalTable th.ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters .ant-table-column-title .anticon-caret-up.ant-table-column-sorter-up.active svg,
.generalTable th.ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters .ant-table-column-title .anticon-caret-down.ant-table-column-sorter-down.active svg,
.generalTable th.ant-table-column-sort span.ant-table-column-sorter-up.active svg,
.generalTable th.ant-table-column-sort span.ant-table-column-sorter-down.active svg {
  color: #1777ff;
}

//settings General table
// .generalTable  {
//   position: relative;
// }
.generalTable .ant-popover {
  // top: 52px !important;
  height: 90%;
  min-height: 300px;
  overflow: auto;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.generalTable .ant-popover.ant-pro-table-column-setting-overlay {
  width: 350px;
}

.generalTable .ant-popover-inner-content {
  width: 100%;

  svg {
    font-size: 16px;
  }
}

.generalTable .ant-pro-table-column-setting-title {
  .ant-checkbox-wrapper {
    * {
      display: none;
    }
  }
}

// pagination
.generalTable .ant-pagination > li:not(:last-child) {
  display: none;
}

//actions fixed
.fixedRow {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none !important;

  button {
    padding: 0;
    font-size: 24px;
    color: #50626c;

    &:hover,
    &:focus {
      background: none;
    }
  }
}

/** TABLE **/
.ant-table-caption {
  position: absolute;
  top: -35px;
}

/**USER HEADER SELECT **/
.userSelect .ant-select-selection-item {
  font-size: 16px;
}

.attach-user-to-account-modal .ant-select-dropdown * {
  transition-duration: 0s !important;
  transition-delay: 0s !important;
  animation-duration: 0s !important;
  animation-delay: 0s !important;
}

.ant-table-cell {
  height: 64px;
  font-family: 'Lato' !important;
  color: $neutral900;
  line-height: 24px !important;
  font-size: 16px !important;

  & * {
    font-family: 'Lato' !important;
    // max-width: 100%;
  }

  & .ant-pro-card {
    background-color: unset;
  }

  &:has(>.nestedCell) {
    height: auto;
  }
}

.ant-btn {
  & span {
    font-weight: 600;
  }
}

.ant-btn-primary.ant-btn-background-ghost {
  box-shadow: 0px 0px 4px #48c5db;
}

.ant-pro-table-list-toolbar-setting-item {
  font-size: 18px;
}

/**** Completed But Not Paid Jobs Table *****/
.completedNotPaidTable tr[data-row-key]>td:first-child {
  padding: 12px 8px !important;
}

/**** Select for Order and Job Statuses *****/
.selectJobOrderStatus {
  width: 100px !important;
  height: 22px !important;
  background: rgba(247, 148, 29, 0.15);
  border: none !important;
  border-radius: 2px;

  .ant-tooltip-open {
    font-size: 12px;
  }

  >.ant-select-selector {
    height: 22px !important;
    padding: 0 28px 0 10px !important;
    border: none !important;

    .ant-select-selection-item {
      padding: 0 !important;
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
    }
  }

  .ant-select-arrow {
    font-size: 12px;
    padding-right: 10px;
    margin: 0;
    right: 0;
    transform: translate(0, -50%);
  }

  &__dropdown {
    padding: 0;

    .ant-tooltip-open {
      font-size: 12px;
    }

    .ant-select-item.ant-select-item-option {
      min-height: 22px;
      padding: 0px 10px;

      .ant-select-item-option-content {
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
      }
    }
  }

  @media (min-width: 768px) {
    margin-left: 8px;
  }
}

.selectJobOrderStatus.selectJobStatus {
  &__undefined {
    .ant-select-selector {
      color: #3F505A;
      background: #F3EFEB;
    }
  }
  
  &__Accepted {
    .ant-select-selector {
      color: #c16a00;
      background: #fdead2;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #c16a00;
    }
  }

  &__Available {
    .ant-select-selector {
      color: #2e8206;
      background: #e2f5da;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #2e8206;
    }
  }

  &__Expired {
    .ant-select-selector {
      color: #bd223d;
      background: #f7cdd4;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #bd223d;
    }
  }

  &__Submitted {
    .ant-select-selector {
      color: #ffffff;
      background: #F7941D;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #ffffff;
    }
  }

  &__Pending {
    .ant-select-selector {
      color: #007d9c;
      background: #dcf2f5;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #007d9c;
    }
  }

  &__Completed {
    .ant-select-selector {
      color: #ffffff;
      background: #52a436;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #ffffff;
    }
  }

  &__Rejected {
    .ant-select-selector {
      color: #cb1b16;
      background: #fae8e8;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #cb1b16;
    }
  }

  &__Declined {
    .ant-select-selector {
      color: #ffffff;
      background: #ff9c94;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #ffffff;
    }
  }

  &__Returned {
    .ant-select-selector {
      color: #fff;
      background: #C3E0E5;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #fff;
    }
  }

  &__Cancelled {
    .ant-select-selector {
      color: #ffffff;
      background: #B3BFC6;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #ffffff;
    }
  }
}

.selectJobOrderStatus.selectOrderStatus {
  &__undefined {
    .ant-select-selector {
      color: #3F505A;
      background: #F3EFEB;
    }
  }

  &__Draft {
    .ant-select-selector {
      color: #3F505A;
      background: #F3EFEB;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #3F505A;
    }
  }

  &__Template {
    .ant-select-selector {
      color: #3F505A;
      background: #D5DBDF;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #3F505A;
    }
  }

  &__Submitted {
    .ant-select-selector {
      color: #C16A00;
      background: #FDEAD2;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #C16A00;
    }
  }

  &__Pending {
    .ant-select-selector {
      color: #007D9C;
      background: #DCF2F5;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #007D9C;
    }
  }

  &__Rejected {
    .ant-select-selector {
      color: #CB1B16;
      background: #FAE8E8;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #CB1B16;
    }
  }

  &__Approved {
    .ant-select-selector {
      color: #2E8206;
      background: #E2F5DA;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #2E8206;
    }
  }

  &__Published, &__Accepted {
    .ant-select-selector {
      color: #FFFFFF;
      background: #52A436;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #FFFFFF;
    }
  }

  &__Completed {
    .ant-select-selector {
      color: #FFFFFF;
      background: #4EBCCF;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #FFFFFF;
    }
  }

  &__Expired {
    .ant-select-selector {
      color: #BD223D;
      background: #F7CDD4;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #BD223D;
    }
  }

  &__Cancelled {
    .ant-select-selector {
      color: #ffffff;
      background: #B3BFC6;
    }

    .ant-select-selector,
    .ant-select-arrow,
    .ant-select-selection-item {
      color: #ffffff;
    }
  }
}


// *** TH and TD for Table when row selection is present - Checkbox or Radio  ****/
.rowSelectionTable th:first-child,
.rowSelectionTable tr[data-row-key]>td:first-child {
  padding: 12px 8px !important;
  text-align: left !important;
}

.rowSelectionTable colgroup col.ant-table-selection-col,
.rowSelectionTableCentered colgroup col.ant-table-selection-col{
  width: 60px !important;
}

.rowSelectionTableCentered th:first-child,
.rowSelectionTableCentered tr[data-row-key]>td:first-child {
  padding: 12px 8px !important;
  text-align: center !important;
}

///**** INPUT DATE PICKER WIDTH 100% ****/
.inputDateFullWidth .ant-picker {
  width: 100%;
}

//**** DISPLAY : NONE *****/
.displayNone {
  display: none;
}

//**** MODAL WINDOW: JOB CONTENT *****/
.modalWindowContent {
  .ant-modal-header {
    margin-bottom: 0;
  }

  .ant-modal-content {
    padding: 0;
  }

  .slick-slide {
    width: 100%;
    height: calc(100vh - 65px);

    >div,
    >div>div {
      height: 100%;
    }

    video {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #000
    }
  }
}

.drawerRootContentModal {
  top: 64px;
  z-index: 10002;

  .ant-drawer-content-wrapper {
    // width: 480px !important;
    box-shadow: none;
    border: 1px solid #B3BFC6;
  }

  .ant-drawer-body {
    padding: 0;
  }
}

.drawerTabsContentModal {
  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 160px;
    margin: 0 !important;
    justify-content: center;
  }
}

/***** orderJobForm DESCRIPTION TAB ORDER JOB PAGE ********/
.orderJobForm {
  flex: 1;

  .ant-select-selection-item,
  input[disabled],
  input[disabled]::placeholder,
  input::placeholder,
  input {
    color: #132b3a;
  }
}

.uploadedFilesModal  {
  .ant-upload.ant-upload-select {
    display: block;
    padding-bottom: 12px;
  }
  .ant-upload-list.ant-upload-list-picture {
    height: auto;
    max-height: 450px;
    overflow: auto;
  }
}

/***** orderJobForm Evaluation ORDER JOB PAGE ********/
.evaluationRadio {

  .ant-radio-button-wrapper-disabled,
  .ant-radio-button-wrapper-disabled:hover {
    background-color: transparent;
    color: transparent;
  }

  .ant-radio-button-wrapper-checked {
    svg {
      fill: #FFE600;
    }
  }
}

/****** ANT CALENDAR DATE PICKER ***********/
.ant-picker-cell-inner {
  height: 28px !important;
  line-height: 28px !important;
  min-width: 30px !important;
}
.ant-picker-cell.ant-picker-cell-in-view {
  min-width: 30px !important;
  padding-top: 3px  !important;
  padding-bottom: 3px  !important;
}
@media (max-width: 768px) {
  .ant-picker.ant-picker-range,
  .ant-picker-dropdown .ant-picker-date-panel .ant-picker-content,
  .ant-picker-dropdown-range,
  .ant-picker-dropdown .ant-picker-date-panel {
    width: 320px;
  }
  .ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
    padding: 8px 0;
  }
  table.ant-picker-content thead th {
    width: auto;
  }
  .ant-picker-dropdown .ant-picker-datetime-panel {
    flex-direction: column;
  }
  .ant-picker-presets {
    display: none !important;
  }
  .ant-picker-time-panel {
    display: none !important;
  }
}

.ff-base-menu-vertical-item-title {
  justify-content: center;
}

.ant-tooltip {
  z-index: 22222;
}

/*** Tabs before table *****/
@media only screen and (max-width: 768px) {
  .ant-tabs-nav {
    flex-wrap: wrap;
    gap: 15px;
  }
}
// /***** Content Table ********/
// .contentTable {
//   .ant-pro-table-list-toolbar-left {
//     width: 10%;
//     max-width: 10%;
//   }
// }

//Pro Layout Sider Menu
// .ant-layout-sider.ff-sider {
//   z-index: 1001;
// }