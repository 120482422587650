@import 'src/shared/styles/_variables.scss';

.rootPage {
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page {
  padding: 20px 40px;
}

.pageExpanded {
  padding: 8px;
  background-color: $neutral0;
}

@media only screen and (min-width: 768px) {
  .pageExpanded {
    padding: 16px 32px 20px;
  }
}

.title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 24px;
  color: #132b3a;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.footer {
  margin-top: 20px;
}