.completedJobsTable th:first-child {
  padding: 12px 8px !important;
}

.payBtn {
  height: 40px;
  padding: 6px 18px;
  font-size: 16px;
  font-weight: 600;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.actions {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;

  > span {
    line-height: 40px;
  }
}

.headerCompletedJobs {
  flex-wrap: wrap;
  gap: 10px;
}

.generalSum {
  width: 100%;
  order: 1;
  margin-top: 15px;
  line-height: 25px;
}

.currentGeneralSum {
  display: flex;
  gap: 10px;
  width: 100%;
  order: 2;
  margin-top: 15px;
}

.title {
  color: #70838E;
  margin-right: 5px;
  font-weight: 900;
}

.currentlyTitle {
  color: #132B3A;
  text-decoration: underline;
}

.value {
  color: #132B3A;
}

.sumRow {
  margin-right: 10px;
}

.sumRow::after {
  content: ';';
  margin-left: 2px;
}