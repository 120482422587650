.menuItem {
  font-size: 16px;
  color: #50626c;

  button:hover,
  button:focus {
    background: none;
  }
}

.btnAddNewAddress {
  display: flex;
  align-items: center;
  margin-left: 20px;

  svg {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 16px;
    color: #50626c;
  }

  &:hover,
  &:focus {
    svg {
      color: #0091B5;
    }
  }

  &:active {
    svg {
      color: #096dd9;
    }
  }
}

.action {
  li {
    padding: 0 !important;
  }

  li:hover,
  li:focus,
  li:active {
    background-color: transparent !important;
  }

  button {
    width: 100%;
    text-align: left;
    margin: 0 0 4px 0;
  }
}

.hideMainAddrr li[data-menu-id*="Main"] {
  display: none !important;
}
