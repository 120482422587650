@import 'src/shared/styles/_variables.scss';

.notification {
  display: flex;
  padding-left: 12px;
  margin: 0;
  border-top: 1px solid $neutral100;
  margin-top: 17px;

  > div:first-child {
    margin-right: 10px;
    width: 350px;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #132b3a;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  span {
    font-size: 16px;
    color: #3f505a;
  }

  li > div {
    margin-bottom: 16px;
  }

  &__email {
    padding: 24px 0 0 12px;
    margin-top: 12px;
    margin-bottom: 0;
    border-top: 1px solid $neutral100;

    label {
      font-weight: 400;
      font-size: 14px;
      color: #50626c;
    }

    input {
      height: 40px;
    }
  }

  &__btns {
    text-align: right;
    margin: 64px 0 0 0;

    button {
      font-size: 16px;
      font-weight: 600;
      height: 40px;
      padding: 6px 16px;

      &:first-child {
        margin-right: 9px;
      }
    }
  }
}
