@import 'src/shared/styles/_variables.scss';

.actions {
  position: fixed;
  right: 35px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  z-index: 2;
  background-color: white;

  &__btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 8px;
    }
  }

  &__dropdown {
    width: auto;
    display: inline-block;

    >button:first-child {
      display: none;
    }
  }
}

.list {
  ul {
    width: 220px;
    padding: 8px 0 !important;

    li {
      padding: 0 !important;

      button {
        width: 100%;
        padding: 9px 24px 9px;
        text-align: left;
        height: auto;
        background: transparent !important;

        span {
          font-weight: 400;
        }
      }
    }
  }
}

.dividerAfter {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #D5DBDF;
  }
}

@media only screen and (max-width: 768px) {
  .actions {
    right: 8px;
  }
}