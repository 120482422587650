.exif {
  padding: 16px 38px;
  p,
  li {
    position: relative;
    margin-bottom: 13px;
  }
  span {
    font-weight: 400;
    font-size: 16px;
  }
  &__label {
    color: #70838E;
  }
  &__value {
    color: #132B3A;
  }
  &__title {
    margin-top: 30px;
    font-weight: 600;
    font-size: 18px;
  }
  ul {
    margin-left: 10px;
  }
  li {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      display: inline-block;
      width: 4px;
      height: 4px;
      margin-left: -10px;
      background: #50626C;
      border-radius: 50%;
      transform: translate(0, -50%);
    }
    span:last-child {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

.badgeEdited {
  width: 56px;
  font-weight: 700;
  font-size: 12px;
  padding: 3px 10px;
  margin-top: 12px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #70838E;
  background: #EBF0F3;
  border-radius: 34px;
}