.dataTitle {
  text-transform: capitalize;
}

.orderType {
  svg {
    font-size: 18px;
    color: #007d9c;
    margin-right: 8px;
  }
}
.requestTitle {
  font-weight: 600;
  font-size: 16px;
  color: #132b3a;
  text-transform: capitalize;
}

[data-style='job_title'] {
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  color: #132b3a;
}

.requestStatus {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #70838e;
}

.iconStatus {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
}

.drawerLink {
  padding: 0;
  height: auto;
  text-align: left;
  > span {
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 120px; 
    white-space: nowrap;
  }
}

[data-status='status__Approved'] {
  background: #38a306;
}

[data-status='status__Submitted'] {
  background: #f7941d;
}

[data-status='status__Rejected'],
[data-status='status_Failed'],
[data-status='status__Deleted'] {
  background: #fc594c;
}
