.extraData {
  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  &__label {
    display: inline-block;
    margin-right: 5px;
    color: #70838E;
  }

  &__value {
    color: #132B3A;
  }
}
