.tab {
  height: 38px;
  padding-bottom: 10px;
  margin: 0;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #50626c;

  &.activeTab {
    font-weight: 700;
    color: #132b3a;
  }
}
.tabs {
  margin-top: 25px;
}
// .table {
//     &__subtitle {
//         font-weight: 400;
//         font-size: 14px;
//         color: #3F505A;
//     }
// }

.table__listJobs tr:not(:first-child) td {
  height: 64px;
  padding: 0 0 0 16px !important;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #132b3a;
}

.table__listJobs td:not(:first-child),
.table__listJobs th:not(:first-child) {
  min-width: 150px;
  max-width: 250px;
}

.table__listJobs td:last-child {
  text-align: center;
}

//Toolbar tab styles

.table__listJobs
  > div
  > div
  > div:first-child
  > div:first-child
  > div:first-child
  > div {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 14px;
  color: #3f505a;

  // Ant-tabs-nav-list
  div > div:first-child > div:first-child > div {
    padding-bottom: 0;
  }
}

.table__listJobs
  > div
  > div
  > div
  > div:last-child
  > div
  > div:first-child
  > div:first-child
  > div
  > div {
  margin-bottom: 0;
  padding-bottom: 0;
}

// Column Styles

[data-style='job_id'] {
  color: #3f505a;
}

[data-style='job_type'] {
  svg {
    margin-right: 8px;
    font-size: 18px;
    color: #007d9c;
  }
}

[data-style='job_status'] {
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: 14px;
  color: #70838e;
}

.job__icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;

  &_paid {
    background: green;
  }

  &_notPaid {
    background: #fc594c;
  }
}

.user {
  &__attachment {
    svg {
      font-size: 24px;

      > path:first-child {
        fill: #f3f7f9;
      }

      > path:last-child {
        fill: #03a3bf;
      }
    }
  }
}

.tabs {
  margin-top: 37px;
}

.menu {
  width: 110px;
  font-size: 16px;
  color: #3f505a;
  line-height: normal;
  border-bottom: none;

  > li:first-child {
    padding: 0 !important;

    &::after {
      border-bottom: none !important;
    }
  }
}

.disabledTab {
  cursor: pointer;
}

.menuRadio {
  font-size: 16px;

  > li {
    font-size: 16px;
    background-color: #fff !important;
  }
}

.menuTitle {
  display: flex;
  align-items: center;
  height: 25px;
  font-size: 16px;
  color: #3f505a;
  padding-right: 20px;

  svg {
    margin-left: 10px;
    color: #3f505a;
  }
}

// Notes
.notes {
  height: calc(100vh - 450px) !important;
  max-width: 100% !important;
}
