.btn {
  display: flex;
  align-items: center;
}

.link {
  color: #0091b5;
  cursor: pointer;
}

.inputNum {
  width: 100%;
}