.comment {
  display: flex;
  flex-direction: column;
  height: 100%;
  // padding: 0 14px 16px 14px;

  &__header>div>div {
    padding: 16px 0px !important;
  }

  &__icon {
    font-size: 16px;
    color: #50626C;
  }

  &__panel {
    border-bottom: 1px solid #D5DBDF !important;

    p {
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      margin-right: 10px;
      color: #50626C;
    }
  }

  &__panel>div:not(:first-child),
  &__panel>div:not(:first-child)>div {
    padding: 0 !important;
  }

  &__body {
    flex: 1 1;
    overflow: auto;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__item.internal {
    align-items: flex-end;
  }

  &__item.user {
    align-items: flex-start;
  }

  &__content {
    max-width: 95%;
    padding: 14px 20px 10px;
    border-radius: 4px;
  }

  &__date {
    width: 100%;
    margin: 8px 0;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #70838E;
  }

  &__sender {
    display: inline-block;
    margin-right: 5px;
    font-weight: 600;
    font-size: 18px;
    color: #132B3A;
  }

  &__target {
    font-weight: 400;
    font-size: 16px;
    color: #70838E;
  }

  &__msg {
    padding: 9px 0;
    font-weight: 400;
    font-size: 16px;
    white-space: pre-line;
    color: #3F505A;
  }

  &__time {
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: #70838E;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    padding: 12px;
    margin-top: 16px;
    background: #EBF0F3;
    border-radius: 2px;

    textarea {
      height: 100%;
      margin-right: 10px;
      resize: none;
      background: transparent;
      border: none;

      &:focus {
        outline: 1px solid #b3bfc6;
        border-color: #b3bfc6;
        box-shadow: none;
        transition: none;
      }
    }
  }
}

.fixedComments {
  height: 500px;
}

.internal .comment__content {
  background: #E6F1F3;
}

.user .comment__content {
  background: #C3E0E6;
}

.relativeLink {
  display: inline-block;
  text-transform: capitalize;
  margin-right: 3px;
  font-weight: 600;
}
