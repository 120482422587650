.content {
  text-align: center;
}

.icon {
  margin-bottom: 24px;
}

.text{
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #50626C;
  text-align: center; 
  resize: none;
}

.actions {
  width: 100%;
  max-width: 336px;
  padding-top: 30px;
  margin: 0 auto;
  text-align: left;
  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  button {
    width: 100%;
    max-width: 160px;
  }
}

.confirmCheckbox {
  margin-bottom: 11px;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #132B3A;
}

.list {
  text-align: left;
  list-style-type: disc;
  width: 300px;
  width: auto;
  display: inline-block;
  margin: 20px 0 0 0;
   li {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #50626C;
    text-transform: capitalize;
   }
}