.modal_title {
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  font-family: 'Poppins' !important;
  color: #132b3a;
}

// .modal > div:nth-of-type(2) > div:nth-of-type(3) {
//   padding: 24px;
//   border: none;
// }

.modal > div:nth-of-type(2) > div:nth-of-type(3) button {
  padding: 6px 30px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.2px;
  height: 40px;
}
