@import 'src/shared/styles/_variables.scss';

.mapSpinner {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $googleMapGrey;
  transition: width 100ms ease-in-out;
}

.containerCollapsed {
  width: 40%;
  transition: width 100ms ease-in-out;
}

.collapseButton {
  width: 40px !important;
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 10;
  background-color: $neutral0;
}

.updateButton {
  height: 40px;
  padding: 0 10px;
  margin-right: 60px;
  margin-top: -50px;

  font-weight: bold;
  font-family: 'Lato';
  font-size: 18px;
  background-color: $neutral700;
  color: $white;
  border: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  &:hover {
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 30%) 1px 2px 4px -1px;
    background-color: $neutral600;
  }
}

.map {
  user-select: none;
  position: relative;
  height: 100%;
}
