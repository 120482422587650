.radioGroup {
  display: flex;
  gap: 8px;
  label[class*="checked"]{
    span:last-of-type {
      color: white;
    }
  }
}

.capitalizeText {
  text-transform: capitalize;
}